import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/c02d041c2aa3a370c434e28d6fe001c431a8a03eb97598a845c5c8e90103232c/navBarModifier';
import SharedProviders_1_0 from './extensions/multivendor-ui-extensions/providers';
import SharedProviders_2_0 from './extensions/software-houses/providers';
import SharedProviders_3_0 from './extensions/statistics/providers';
import SharedProviders_4_0 from './extensions/credits-and-highlights/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0, ...SharedProviders_3_0, ...SharedProviders_4_0],
})
export class SharedExtensionsModule {}
